import { whatsappBuilder } from './views/whatsappList';

const whatsappManagementRoutes = [
  {
    path: '/',
    name: 'whatsapp',
    component: whatsappBuilder,
    meta: {
      menuTitle: 'WhatsApp Conversations',
    },
  },
];

export default whatsappManagementRoutes;
