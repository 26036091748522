<template>
  <div class="d-flex flex-row justify-content-start">
    <div class="mx-3">
      <label>Select type of interaction:</label>
      <a-select
        class="m-0 mb-1"
        v-model:value="selectedInteraction"
        placeholder="Select type of interaction..."
        option-label-prop="label"
        @change="selectInteraction"
        allowClear
        style="width: 100%"
        show-search
      >
        <a-select-option value="input" label="Input"> Input ( Trigger as well )</a-select-option>
        <a-select-option value="output" label="Output"> Output </a-select-option>
      </a-select>
    </div>
    <div class="mx-3">
      <label>Select a Plugin as source data:</label>
      <a-select
        class="m-0 mb-1"
        v-model:value="selectedPlugin"
        placeholder="Select a Plugin..."
        option-label-prop="label"
        @change="selectPlugin"
        allowClear
        style="width: 100%"
        show-search
      >
        <a-select-option v-for="plugin of pluginInstalledList" :key="plugin._id" :value="plugin._id" :label="plugin.title">
          {{ plugin.title }}
        </a-select-option>
      </a-select>
    </div>
    <div v-if="selectedPlugin" class="mx-3">
      <label>Select an Endpoint to link:</label>
      <a-select
        class="m-0 mb-1"
        v-model:value="selectedEndpoint"
        placeholder="Select a Plugin Endpoint..."
        option-label-prop="label"
        @change="selectEndpoint"
        allowClear
        style="width: 100%"
        show-search
      >
        <a-select-option
          v-for="endpoint of endpointList"
          :key="endpoint.path"
          :value="endpoint.path"
          :label="`${endpoint.name} ( ${endpoint.path} )`"
        >
          {{ endpoint.name }} ( {{ endpoint.path }} )
        </a-select-option>
      </a-select>
    </div>
    <div v-if="selectedEndpoint" class="mx-3">
      <label>Select a Field to link:</label>
      <a-select
        class="m-0 mb-1"
        v-model:value="selectedField"
        placeholder="Select a Plugin Field..."
        option-label-prop="label"
        @change="selectField"
        allowClear
        style="width: 100%"
        show-search
      >
        <a-select-option v-for="field of fieldList" :key="field.name" :value="field.name" :label="`${field.name} ( ${field.type} )`">
          {{ field.name }} ( {{ field.type }} )
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script>
import { PluginsActions, PluginsGetters } from '@/apps/pluginsManagement/shared/pluginsManagement.store';
import PluginsManagementApi from '@/apps/pluginsManagement/api/pluginsManagement.api';

export default {
  name: 'PluginFieldManager',
  props: ['field', 'triggers'],
  emits: ['update:modelValue'],
  data() {
    return {
      pluginList: [],
      pluginInstalledList: [],
      endpointList: [],
      inputFieldList: [],
      outputFieldList: [],
      selectedInteraction: null,
      selectedPlugin: null,
      selectedEndpoint: null,
      selectedField: null,
    };
  },
  async created() {
    try {
      await this.loadPluginList();

      this.selectedPlugin = this.field?.pluginId ?? null;
      this.selectedEndpoint = this.field?.pluginEndpoint ?? null;
      this.selectedField = this.field?.pluginField ?? null;
      this.selectedInteraction = this.field?.pluginInteraction ?? null;

      if (this.selectedPlugin) {
        const plugin = this.pluginInstalledList?.find(item => item._id.toString() === this.selectedPlugin);
        if (plugin) {
          const pluginData = await PluginsManagementApi.getPluginStructure(plugin.path);
          this.endpointList = pluginData.endPoints || [];

          if (this.selectedEndpoint) {
            const endpoint = this.endpointList.find(item => item.path === this.selectedEndpoint);
            this.inputFieldList = Object.entries(endpoint.inputFields).map(([key, value], index) => ({
              name: key,
              type: value,
              _id: index,
            }));

            this.outputFieldList = Object.entries(endpoint.expectedFields).map(([key, value], index) => ({
              name: key,
              type: value,
              _id: index,
            }));
          }
        }
      }
    } catch (error) {
      console.error('Error in created lifecycle hook:', error);
    }
  },
  computed: {
    fieldList() {
      return this.selectedInteraction === 'input' ? this.inputFieldList : this.outputFieldList;
    },
  },
  methods: {
    async loadPluginList() {
      try {
        await PluginsActions.fetchPluginsList();
        this.pluginList = PluginsGetters.getAll();
        this.pluginInstalledList = await PluginsManagementApi.getPluginsByApp();
      } catch (error) {
        console.error('Failed to load plugin list:', error);
        this.pluginInstalledList = [];
      }
    },
    async selectInteraction(interaction) {
      this.selectedInteraction = interaction;
      this.$emit('update:modelValue', { ...this.field, pluginInteraction: interaction });
    },
    async selectPlugin(pluginId) {
      try {
        if (!pluginId) {
          this.resetSelections();
          return;
        }

        this.selectedPlugin = pluginId;
        const plugin = this.pluginInstalledList.find(item => item._id === pluginId);
        if (plugin) {
          const pluginData = await PluginsManagementApi.getPluginStructure(plugin.path);
          this.endpointList = pluginData.endPoints || [];
          this.$emit('update:modelValue', { ...this.field, pluginId });
        }
      } catch (error) {
        console.error('Error selecting plugin:', error);
      }
    },
    async selectEndpoint(endpointPath) {
      try {
        if (!endpointPath) {
          this.selectedEndpoint = null;
          this.selectedField = null;
          this.$emit('update:modelValue', { ...this.field, pluginEndpoint: null, pluginField: null });
          return;
        }

        this.selectedEndpoint = endpointPath;
        const endpoint = this.endpointList.find(item => item.path === endpointPath);
        this.inputFieldList = Object.entries(endpoint.inputFields).map(([key, value], index) => ({
          name: key,
          type: value,
          _id: index,
        }));

        this.outputFieldList = Object.entries(endpoint.expectedFields).map(([key, value], index) => ({
          name: key,
          type: value,
          _id: index,
        }));

        this.$emit('update:modelValue', { ...this.field, pluginEndpoint: endpointPath });
      } catch (error) {
        console.error('Error selecting endpoint:', error);
      }
    },
    async selectField(fieldName) {
      this.selectedField = fieldName;
      this.$emit('update:modelValue', { ...this.field, pluginField: fieldName });
    },
    resetSelections() {
      this.selectedInteraction = null;
      this.selectedPlugin = null;
      this.selectedEndpoint = null;
      this.selectedField = null;
      this.$emit('update:modelValue', {
        ...this.field,
        pluginInteraction: null,
        pluginId: null,
        pluginEndpoint: null,
        pluginField: null,
      });
    },
  },
};
</script>
