<template>
  <div>
    <h3>
      <BuildOutlined />
      WhatsApp Conversations
      <a-badge
        :count="whatsapp.total"
        :number-style="{
          backgroundColor: '#fff',
          color: '#999',
          boxShadow: '0 0 0 1px #d9d9d9 inset',
        }"
      />
    </h3>

    <ConversationModal :conversationId="selected" :openModal="openModal" @closeModal="closeModal" />

    <a-table
      bordered
      :locale="{ emptyText: '-' }"
      size="middle"
      :loading="loading"
      :columns="tableColumns"
      :data-source="whatsapp.data"
      :pagination="false"
      @change="handleSorter"
    >
      <template #customFilterIcon="{ filtered, column }">
        <SearchOutlined v-if="column.key?.includes('message')" :style="{ color: filtered ? '#108ee9' : undefined }" />
        <FilterOutlined v-else :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div style="width: 200px; padding: 8px; display: block">
          <template v-if="column.key === 'createdAt' || column.key === 'updatedAt'">
            <a-date-picker
              :readOnly="true"
              :placeholder="'From'"
              :format="'DD.MM.YYYY'"
              :value="selectedKeys[0] ? dayjs(new Date(selectedKeys[0])) : undefined"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="value => setSelectedKeys(value ? [value, selectedKeys[1]] : [null, selectedKeys[1]])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <a-date-picker
              :readOnly="true"
              :placeholder="'To'"
              :format="'DD.MM.YYYY'"
              :value="selectedKeys[1] ? dayjs(new Date(selectedKeys[1])) : undefined"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="value => setSelectedKeys(value ? [selectedKeys[0], value] : [selectedKeys[0], null])"
            />
          </template>
          <template v-else>
            <a-input
              allowClear
              :placeholder="`Cauta in '${column.title}'`"
              :value="selectedKeys[0]"
              style="margin-bottom: 8px"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            />
          </template>

          <a-button
            type="primary"
            size="small"
            style="width: 88px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" style="width: 88px; position: absolute" @click="handleReset(clearFilters, confirm)">Reset</a-button>
        </div>
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'messages'">
          <a-button @click="selectRow(record._id)">Vizualizeaza conversatia...</a-button>
        </template>
        <template v-else-if="column.key === 'createdAt' || column.key === 'updatedAt'">
          <a>
            {{
              new Date(record[column.key]).toLocaleDateString('ro-RO', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              })
            }}
          </a>
        </template>
      </template>
    </a-table>
    <a-pagination
      :current="Number(whatsapp.page)"
      :page-size="Number(whatsapp.limit)"
      :size="'default'"
      :total="Number(whatsapp.total)"
      :showTotal="total => `${total} conversatii.`"
      @change="onChange"
      style="padding: 10px 0"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/ro';

import { BuildOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons-vue';
import { WhatsAppActions, WhatsAppGetters } from '../../shared/whatsapp.store';
import ConversationModal from './components/ConversationModal.vue';

export default {
  data() {
    return {
      loading: false,
      visible: false,
      selected: 0,
      openModal: false,
      tableColumns: [],
      whatsapp: {
        page: 1,
        pages: 0,
        total: 0,
        limit: 10,
        data: [],
      },
      sorters: {},
      filters: {},
    };
  },
  props: ['instanceId'],
  async mounted() {
    this.loading = true;

    await this.fetchWhatsAppData(this.whatsapp.page, this.whatsapp.limit);
    this.columns();

    this.loading = false;
  },
  computed: {
    dayjs() {
      return dayjs;
    },
  },
  methods: {
    columns() {
      this.tableColumns = [
        {
          dataIndex: 'to',
          title: 'Message To',
          key: 'to',
          customFilterDropdown: true,
          sorter: true,
        },
        {
          dataIndex: 'messages',
          title: 'Messages',
          key: 'messages',
          customFilterDropdown: true,
        },
        {
          dataIndex: 'createdAt',
          title: 'Initiated At',
          key: 'createdAt',
          customFilterDropdown: true,
          sorter: true,
        },
        {
          dataIndex: 'updatedAt',
          title: 'Last action At',
          key: 'updatedAt',
          customFilterDropdown: true,
          sorter: true,
        },
      ];
    },
    async fetchWhatsAppData(page, limit) {
      this.loading = true;

      if (this.instanceId) {
        await WhatsAppActions.fetchForInstance(this.instanceId);
      } else {
        await WhatsAppActions.fetchForSubtenant(page, limit, this.sorters, this.filters);
      }

      this.whatsapp = WhatsAppGetters.getAll();
      this.columns();

      this.loading = false;
    },
    async handleSorter(pagination, filters, sorter) {
      if (sorter.order) {
        this.sorters[sorter.columnKey] = sorter.order === 'ascend' ? 'asc' : 'desc';
      } else {
        this.sorters = {};
      }

      await this.fetchWhatsAppData(this.whatsapp.page, this.whatsapp.limit);
    },
    async handleSearch(selectedKeys, confirm, dataIndex) {
      const [firstKey, secondKey] = selectedKeys;

      if (dataIndex === 'sentAt') {
        if (new Date(firstKey) > new Date(secondKey))
          this.filters[dataIndex] = {
            ...(secondKey ? { from: new Date(secondKey) } : {}),
            ...(firstKey ? { to: new Date(firstKey) } : {}),
          };
        else
          this.filters[dataIndex] = {
            ...(firstKey ? { from: new Date(firstKey) } : {}),
            ...(secondKey ? { to: new Date(secondKey) } : {}),
          };
      } else if (dataIndex === 'isSent') {
        if (firstKey === secondKey) {
          this.filters[dataIndex] = null;
        } else if (firstKey === true) {
          this.filters[dataIndex] = true;
        } else if (secondKey === true) {
          this.filters[dataIndex] = false;
        }
      } else {
        this.filters[dataIndex] = firstKey;
      }

      await this.fetchWhatsAppData(this.whatsapp.page, this.whatsapp.limit);
      confirm();
    },
    async handleReset(clearFilters, confirm) {
      clearFilters();
      this.filters = {};

      await this.fetchWhatsAppData(this.whatsapp.page, this.whatsapp.limit);
      confirm();
    },
    onChange(page) {
      this.fetchWhatsAppData(page, this.whatsapp.limit);
    },
    selectRow(convId) {
      this.openModal = convId !== 0;
      this.selected = convId;
    },
    closeModal() {
      this.selectRow(0);
    },
  },
  components: {
    ConversationModal,
    BuildOutlined,
    SearchOutlined,
    FilterOutlined,
  },
};
</script>
