<template>
  <div class="mt-3 padd-10">
    <div v-if="openMenu">
      <a-button @click="back" class="mb-3"><CaretLeftOutlined /> Back</a-button>

      <h4 style="margin: 0">
        {{ openMenu.name }}
        <a-tag color="#87d068" style="top: -4px; position: relative" v-if="openMenu.active">Active</a-tag>
        <a-tag color="#f50" style="top: -4px; position: relative" v-else>Disabled</a-tag>
      </h4>
      <div v-if="openMenu.description !== ''">{{ openMenu.description }}</div>

      <div class="mt-2">
        <a-button type="primary" size="small" class="mb-3" @click="addMenuItem">Add menu item</a-button>

        <div class="card">
          <div class="card-body">
            <nested-menu :depth="0" v-model="localOpenMenu.menuBuilder" @listChange="listChange" @select="onSelect" />
          </div>
        </div>
      </div>

      <component
        :is="'a-modal'"
        :footer="false"
        :width="'800px'"
        v-model:open="modal.show"
        :destroyOnClose="true"
        :title="modal.edit ? 'Edit menu' : 'Add menu'"
        :maskClosable="false"
        @cancel="closeModal"
      >
        <div class="mb-3">
          <a-input v-model:value="formMenuBuilderData.title" placeholder="Name">
            <template v-if="formMenuBuilderData.icon !== ''" #prefix>
              <span v-html="formMenuBuilderData.icon" />
            </template>
          </a-input>
        </div>
        <div class="mb-3">
          <a-tag class="pointer" size="small" @click="showAddIcon = !showAddIcon">
            {{ formMenuBuilderData.icon === '' ? 'Add' : 'Edit' }} icon
          </a-tag>
          <a-textarea
            style="margin-top: 5px"
            v-if="showAddIcon"
            v-model:value="formMenuBuilderData.icon"
            placeholder='SVG Icon (ex: &lt;svg width="22px" height="22px"&gt;&lt;/svg&gt;)'
          />
        </div>

        <div class="mb-3">
          <div class="row">
            <div class="col-2">
              <div style="padding-top: 6px">{{ modal.edit ? 'Move menu' : 'Insert menu' }}:</div>
            </div>
            <div class="col-3">
              <a-select :value="modal.position" v-model:value="modal.position" style="width: 100%">
                <a-select-option value="before">Before</a-select-option>
                <a-select-option value="after">After</a-select-option>
                <a-select-option value="as_child">As child</a-select-option>
              </a-select>
            </div>
            <div class="col-7">
              <a-tree-select
                v-model:value="modal.addMoveToMenuId"
                style="width: 100%"
                :tree-data="openMenu.menuBuilder"
                placeholder="Select menu"
                :fieldNames="{ key: '_id', value: '_id' }"
              />
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row">
            <div class="col-2">
              <div style="padding-top: 6px">For template:</div>
            </div>
            <div class="col-8">
              <a-select
                show-search
                allowClear
                :filter-option="filterTemplates"
                placeholder="Search..."
                :loading="templatesLoading"
                :value="formMenuBuilderData.templateId ?? undefined"
                @change="value => (formMenuBuilderData.templateId = value ?? undefined)"
                style="width: 100%"
              >
                <template v-for="template of templates" :key="template._id">
                  <a-select-option :value="template._id" :label="template.name">{{ template.name }}</a-select-option>
                </template>
              </a-select>
            </div>
            <div class="col-2">
              <router-link
                v-if="formMenuBuilderData.templateId && templates.find(t => t._id === formMenuBuilderData.templateId)?.blueprintId"
                :to="{
                  name: 'TemplateManagementBuilder',
                  params: {
                    blueprintId: templates.find(t => t._id === formMenuBuilderData.templateId)?.blueprintId,
                    templateId: formMenuBuilderData.templateId,
                  },
                }"
                custom
                v-slot="{ href }"
              >
                <a :href="href" target="_blank" class="btn btn-primary btn-sm btn-block">Go to</a>
              </router-link>
            </div>
          </div>
        </div>

        <a-divider></a-divider>

        <div class="mb-3">
          <div class="row">
            <div class="col-2">
              <div style="padding-top: 6px">Subtenants:</div>
            </div>
            <div class="col-1">
              <a-checkbox v-model:checked="formMenuBuilderData.allSubtenants" @change="updateSubtenantList"> All</a-checkbox>
            </div>
            <div class="col-9">
              <a-select
                mode="tags"
                placeholder="Select..."
                :value="formMenuBuilderData.subtenantId"
                v-model:value="formMenuBuilderData.subtenantId"
                style="width: 100%"
                v-if="!formMenuBuilderData.allSubtenants"
              >
                <template v-for="subtenant of subtenants" :key="subtenant._id">
                  <a-select-option :value="subtenant._id">{{ subtenant.name }}</a-select-option>
                </template>
              </a-select>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <div class="row">
            <div class="col-2">
              <div style="padding-top: 6px">Show for roles:</div>
            </div>
            <div class="col-10 mb-1">
              <a-radio-group v-model:value="formMenuBuilderData.showForRoles">
                <a-radio-button value="ALL"> ALL </a-radio-button>
                <a-radio-button value="NONE"> NONE </a-radio-button>
                <a-radio-button value="CUSTOM"> CUSTOM </a-radio-button>
              </a-radio-group>
            </div>

            <div class="col-2" v-if="formMenuBuilderData.showForRoles === 'CUSTOM'">
              <div style="padding-top: 6px">Choose roles:</div>
            </div>
            <div class="col-10" v-if="formMenuBuilderData.showForRoles === 'CUSTOM'">
              <a-select
                mode="tags"
                placeholder="Select..."
                :value="formMenuBuilderData.roleIds"
                v-model:value="formMenuBuilderData.roleIds"
                style="width: 100%"
              >
                <template v-for="appRole of appRolesOptions" :key="appRole._id">
                  <a-select-option :value="appRole._id">{{ appRole.name }}</a-select-option>
                </template>
              </a-select>
            </div>
          </div>
        </div>

        <div class="mb-3">
          <a-switch
            checked-children="Enabled"
            :checked="formMenuBuilderData.active"
            v-model:checked="formMenuBuilderData.active"
            un-checked-children="Disabled"
          />
        </div>

        <!--         <div class="mb-3">
           <a-switch checked-children="Show in menu" :checked="formMenuBuilderData.showInMenu" v-model:value="formMenuBuilderData.showInMenu" un-checked-children="Hide from menu" />
         </div>-->

        <div style="padding-top: 20px">
          <a-row>
            <a-col :span="12">
              <a-button
                :loading="addEditOneLoading === formMenuBuilderData?._id || this.addEditOneLoading === 'addNew'"
                type="primary"
                @click="processForm"
              >
                <template v-if="modal.edit"> <EditOutlined /> Edit </template>
                <template v-else> <PlusOutlined /> Create</template>
              </a-button>
            </a-col>
            <a-col :span="12" style="text-align: right">
              <template v-if="modal.edit">
                <a-popconfirm
                  class="mr-3"
                  placement="topLeft"
                  :title="'Are you sure you want to delete `' + formMenuBuilderData?.title + '`?'"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="removeOne(formMenuBuilderData?._id)"
                >
                  <a-button :loading="deleteOneLoading === formMenuBuilderData?._id" type="link" style="color: red">Delete</a-button>
                </a-popconfirm>
              </template>
              <a-button key="back" @click="closeModal">Cancel</a-button>
            </a-col>
          </a-row>
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import { MenuActions, MenuGetters } from '@menuBuilder/shared/menuBuilder.store';
import { findAndAddMoveRecursive, findAndRemoveRecursive, findRecursive, objectId } from '@/core/utils/array-manipulation';
import { message } from 'ant-design-vue';

import { TenantsGetters } from '@tenants/shared/tenants.store';
import templateManagementApi from '@/apps/templateManagement/templateManagement.api';
import { roleApplicationApi } from '@roleManagement/api';
import { EditOutlined, CaretLeftOutlined, PlusOutlined } from '@ant-design/icons-vue';
import NestedMenu from './components/NestedMenu.vue';

const defaultFormMenuBuilder = {
  title: '',
  icon: '',
  subtenantId: [],
  templateId: undefined,
  settings: {},
  children: [],

  active: true,
  showInMenu: true,
};

export default {
  name: 'MenuBuilder',
  components: {
    NestedMenu,
    CaretLeftOutlined,
    PlusOutlined,
    EditOutlined,
  },
  async created() {
    await MenuActions.init();
    this.localOpenMenu = { ...this.openMenu };

    this.templatesLoading = true;
    this.templates = await templateManagementApi.getAllTemplates();
    this.appRoles = await roleApplicationApi.getAllForApplication();
    this.templatesLoading = false;
  },

  watch: {
    '$route.params.menuId': function (value) {
      this.menuId = value;
    },
    openMenu(value) {
      this.localOpenMenu = { ...value };
    },
  },
  computed: {
    menuLoading: MenuGetters.getMenuLoading,
    menus: MenuGetters.getMenus,
    appRolesOptions() {
      return this.appRoles.map(role => {
        return {
          name: role.name,
          _id: role._id,
        };
      });
    },

    subtenants: TenantsGetters.getAllSubtenants,

    openMenu() {
      const menu = this.menus.find(item => item._id === this.menuId);
      return {
        ...menu,
        menuBuilder: menu?.menuBuilder ?? [],
      };
    },
  },
  data() {
    return {
      templatesLoading: false,
      addEditOneLoading: null,
      deleteOneLoading: null,
      menuId: this.$route.params.menuId ?? null,
      appRoles: [],

      templates: [],

      localOpenMenu: {
        menuBuilder: null,
      },

      formMenuBuilderData: defaultFormMenuBuilder,

      expandedKeys: [],

      showAddIcon: false,

      modal: {
        show: false,
        edit: false,

        position: 'after',
        addMoveToMenuId: undefined,
      },
    };
  },

  methods: {
    async processForm() {
      const { position } = this.modal;
      const { addMoveToMenuId } = this.modal;

      this.addEditOneLoading = this.formMenuBuilderData._id ?? 'addNew';

      let formMenuBuilderData = [...JSON.parse(JSON.stringify(this.openMenu.menuBuilder))];

      if (this.modal.edit) {
        formMenuBuilderData = await findAndAddMoveRecursive(
          [...formMenuBuilderData],
          this.formMenuBuilderData._id,
          this.formMenuBuilderData,
          position,
          addMoveToMenuId
        );
        this.localOpenMenu.menuBuilder = formMenuBuilderData;
      } else if (addMoveToMenuId) {
        formMenuBuilderData = await findAndAddMoveRecursive(
          [...formMenuBuilderData],
          addMoveToMenuId,
          {
            _id: objectId(),
            ...this.formMenuBuilderData,
          },
          position,
          addMoveToMenuId
        );
        this.localOpenMenu.menuBuilder = formMenuBuilderData;
      } else {
        formMenuBuilderData.push({
          _id: objectId(),
          ...this.formMenuBuilderData,
        });
      }

      const formData = {
        ...this.openMenu,
        menuBuilder: formMenuBuilderData,
      };

      await MenuActions.editOne(this.openMenu._id, formData);

      this.addEditOneLoading = null;
      this.closeModal();
    },

    async onSelect(element) {
      const findMenu = await findRecursive(this.openMenu.menuBuilder, element._id);

      this.modal.show = true;
      this.modal.edit = true;

      this.modal.position = 'after';
      this.modal.addMoveToMenuId = undefined;

      this.formMenuBuilderData = { ...findMenu };
      if (typeof this.formMenuBuilderData?.allSubtenants === 'undefined') {
        this.formMenuBuilderData.allSubtenants = this.formMenuBuilderData?.subtenantId?.length === this.subtenants?.length;
      }
    },

    async listChange(data) {
      await MenuActions.editOne(this.openMenu._id, {
        ...this.openMenu,
        menuBuilder: data,
      });
      message.success('Menu updated!');
    },

    async removeOne(menuId) {
      this.deleteOneLoading = menuId;
      const formMenuBuilderData = [...JSON.parse(JSON.stringify(this.openMenu.menuBuilder))];
      const updatedData = await findAndRemoveRecursive([...formMenuBuilderData], menuId);
      this.localOpenMenu.menuBuilder = updatedData;

      await MenuActions.editOne(this.openMenu._id, {
        ...this.openMenu,
        menuBuilder: updatedData,
      });
      this.deleteOneLoading = null;
      message.success('Menu updated!');

      this.closeModal();
    },

    addMenuItem() {
      this.modal.show = true;
      this.modal.edit = false;

      this.formMenuBuilderData.allSubtenants = true;
      this.formMenuBuilderData.subtenantId = this.subtenants?.map(item => item._id);
      this.formMenuBuilderData.showForRoles = 'ALL'; // [ALL, NONE, CUSTOM]
      this.formMenuBuilderData.roleIds = [];

      this.modal.position = 'after';
      this.modal.addMoveToMenuId = undefined;
    },
    closeModal() {
      this.modal.show = false;

      this.modal.position = 'after';
      this.modal.addMoveToMenuId = undefined;

      this.formMenuBuilderData = { ...defaultFormMenuBuilder };
    },
    filterTemplates(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    back() {
      this.$router.push({ name: 'MenuBuilderList' });
    },
    updateSubtenantList(value) {
      if (!value && !this.formMenuBuilderData.subtenantId) {
        this.formMenuBuilderData.subtenantId = this.subtenants?.map(item => item._id);
      }
    },
  },
};
</script>

<style scoped></style>
