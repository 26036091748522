<template>
  <a-modal
    v-model:open="modalInsertOptionsOpen"
    title="Blueprint data insertion options"
    @ok="modalInsertOptionsOpen = false"
    :cancelButtonProps="{ style: { display: 'none' } }"
    :afterClose="() => saveOptions(insertingOptionsForThisBlueprint)"
  >
    <div v-if="insertingOptionsForThisBlueprint">
      {{ insertingOptionsForThisBlueprint }}
      <a-tabs v-model:activeKey="insertOptionsActiveKey">
        <a-tab-pane key="1" tab="Creation">
          <div>
            <a-radio-group v-model:value="insertingOptionsForThisBlueprint.insertingType">
              <a-radio :value="'new'">Always insert as new</a-radio>
              <a-radio :value="'update'">
                Update if exists, insert as new if not
                <help-icon-popover title="Update instances" content="Select one or more fields to determine the instance uniqueness (example: id)."
              /></a-radio>
            </a-radio-group>
          </div>

          <div v-if="insertingOptionsForThisBlueprint.insertingType === 'update'">
            <a-select
              v-model:value="insertingOptionsForThisBlueprint.uniqueFields"
              class="mt-3"
              mode="multiple"
              style="width: 100%"
              placeholder="Select unique field(s)"
            >
              <a-select-option v-for="field in blueprintFields" :key="field._id" :value="field._id" :label="field.label">
                {{ field.label }} ( Field ID: {{ field.successionIndex }} )
              </a-select-option>
            </a-select>
          </div>
        </a-tab-pane>

        <a-tab-pane key="2" tab="Ownership / type">
          <a-radio-group v-model:value="insertingOptionsForThisBlueprint.instanceType">
            <a-radio :value="'default'">
              Default
              <help-icon-popover
                title="Default"
                content="If the associated Dataflow is Manual, the instances will be created only for the Subtenant from which the Dataflow action was initiated. If it is Auto, the instances will be created as duplicates for every Subtenant."
              />
            </a-radio>

            <a-radio :value="'global'">
              Global
              <help-icon-popover
                title="Global instances"
                content="Created instances will be marked as Global and will be available for all Subtenants and all Applications that use this Blueprint."
              />
            </a-radio>

            <a-radio :value="'selective'" disabled="disabled">
              Selective (NOT AVAILABLE)
              <help-icon-popover
                title="Selective Subtenants"
                content="If the selective mode is chosen the instances will be created only for the selected subtenants."
              />
            </a-radio>
          </a-radio-group>
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-modal>

  <div class="saas_card mb-10 mt-2">
    <div class="row-contents">
      <div class="group-text-container">
        <div class="group-text">
          <span class="title">
            <strong> {{ blueprint.name }} </strong>
            <span style="padding-left: 10px">fields</span>
            <a-button class="ml-3" @click="openMapperOptionsModal()">
              <template #icon><SettingOutlined /></template>
              Data insertion options
            </a-button>
          </span>
        </div>
      </div>
      <div class="metadata">
        <div class="controls">
          <a-popconfirm placement="topRight" ok-text="Yes" cancel-text="No" @confirm="onRemoveBlueprint(blueprint._id)">
            <template #title>
              <p>Are you sure you want to delete?</p>
            </template>
            <a-button><DeleteOutlined /></a-button>
          </a-popconfirm>
        </div>
      </div>
    </div>

    <div class="details-container">
      <div class="content">
        <div v-if="blueprintFields.length">
          <template v-for="field in blueprintFields" :key="field._id">
            <drop
              v-if="field.structure.type !== 'reference'"
              class="saas_card mb-10"
              :accepts-data="() => !getField(blueprint._id, field._id)"
              @drop="e => onDropField(blueprint._id, field._id, e)"
            >
              <div class="row-contents">
                <div class="dataRow">
                  <div class="cell">
                    <div class="group-text-container group-text center">
                      <strong>
                        {{ field.label }}
                        <a-tag>Field ID: {{ field.successionIndex }}</a-tag>
                      </strong>
                    </div>
                  </div>
                  <div class="cell l-line" v-if="getField(blueprint._id, field._id)">
                    <SwapOutlined class="middle-icon" />
                    <div class="group-text-container saas_hoverable group-text" style="padding-left: 17px">
                      <strong>
                        {{ getField(blueprint._id, field._id).label }}
                      </strong>
                      <a-popconfirm placement="topRight" ok-text="Yes" cancel-text="No" @confirm="onRemoveField(getField(blueprint._id, field._id))">
                        <template #title>
                          <p>Are you sure you want to delete?</p>
                        </template>
                        <a-button size="small" style="position: absolute; right: 6px"><DeleteOutlined /></a-button>
                      </a-popconfirm>
                    </div>
                  </div>
                </div>
              </div>
            </drop>
            <div v-else class="saas_card mb-10">
              <div class="row-contents">
                <div class="dataRow">
                  <div class="cell">
                    <div class="group-text-container group-text center">
                      <strong>
                        {{ field.label }}
                        <a-tag>Field ID: {{ field.successionIndex }}</a-tag>
                      </strong>
                    </div>
                  </div>
                  <div class="cell l-line">
                    <div class="group-text-container saas_hoverable group-text" style="padding-left: 17px">
                      Blueprint referenced.
                      <a-button
                        v-if="!blueprintsById[field.structure.ruleset.blueprintId]"
                        class="ml-3"
                        @click="onAddBlueprint(field.structure.ruleset.blueprintId)"
                      >
                        <PlusOutlined /> Add Blueprint
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
              <BlueprintNode
                style="margin: 5px"
                v-if="blueprintsById[field.structure.ruleset.blueprintId]"
                :blueprint="blueprintsById[field.structure.ruleset.blueprintId]"
                :blueprintFields="blueprintsById[field.structure.ruleset.blueprintId]?.fields"
                :blueprintsById="blueprintsById"
                :insertingOptions="insertingOptions"
                :getField="getField"
                @save-options="saveOptions"
                @remove-blueprint="onRemoveBlueprint"
                @drop-field="onDropField"
                @remove-field="onRemoveField"
                @add-blueprint="onAddBlueprint"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Drop } from 'vue-easy-dnd';
import { DeleteOutlined, PlusOutlined, SettingOutlined, SwapOutlined } from '@ant-design/icons-vue';

export default {
  name: 'BlueprintNode',
  components: { SettingOutlined, Drop, DeleteOutlined, PlusOutlined, SwapOutlined },
  props: {
    blueprint: { type: Object, required: true },
    blueprintFields: { type: Array, required: true },
    blueprintsById: { type: Object, required: true },
    getField: { type: Function, required: true },
    insertingOptions: { type: Object, required: true },
  },
  data() {
    return {
      insertOptionsActiveKey: '1',
      modalInsertOptionsOpen: false,
      insertingOptionsForThisBlueprint: this.insertingOptions.find(value => value.blueprintId === this.blueprint._id),
    };
  },
  methods: {
    openMapperOptionsModal() {
      this.modalInsertOptionsOpen = true;
    },
    saveOptions(insertingOptionsForThisBlueprint) {
      this.$emit('save-options', insertingOptionsForThisBlueprint);
    },
    onRemoveBlueprint(blueprintId) {
      this.$emit('remove-blueprint', blueprintId);
    },
    onDropField(blueprintId, fieldId, event) {
      this.$emit('drop-field', blueprintId, fieldId, event);
    },
    onRemoveField(field) {
      this.$emit('remove-field', field);
    },
    onAddBlueprint(blueprintId) {
      this.$emit('add-blueprint', blueprintId);
    },
  },
};
</script>
<style scoped>
.dataRow {
  position: relative;
  display: table;
  width: 100%;
}
.dataRow .cell {
  width: 50%;
  display: table-cell;
}
.dataRow .cell.pl-15 {
  padding-left: 15px;
}
.dataRow .cell.l-line {
  border-left: 1px solid #ddd;
}

.middle-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2px 3px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.saas_hoverable:hover {
  background-color: rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  cursor: pointer;
}
</style>
