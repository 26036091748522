import { reactive } from 'vue';

import { WhatsAppManagementApi } from '../api';

const initialState = {
  conversations: {},
};

const state = reactive({ ...initialState });

const Getters = {
  getAll: () => state.conversations,
};

const Mutations = {
  SET_WHATSAPP_CONVERSATIONS: payload => {
    state.conversations = payload;
  },
};

const Actions = {
  fetchForSubtenant: async (page = 1, limit = 10, sorters = {}, filters = {}) => {
    const conversations = await WhatsAppManagementApi.findAllForSubtenant(page, limit, sorters, filters);

    Mutations.SET_WHATSAPP_CONVERSATIONS(conversations);
  },
  fetchForInstance: async (instanceId, page = 1, limit = 10, sorters = {}, filters = {}) => {
    const conversations = await WhatsAppManagementApi.findAllForInstance(instanceId, page, limit, sorters, filters);

    Mutations.SET_WHATSAPP_CONVERSATIONS(conversations);
  },
};

export const WhatsAppGetters = Getters;
export const WhatsAppMutations = Mutations;
export const WhatsAppActions = Actions;
